import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArtiFormsService } from '@app/artiform-service/arti-forms.service';
import { EmailEditorComponent } from '@app/email-editor/email-editor.component';
import { QrCodeDisplayComponent } from '@app/qr-code-display/qr-code-display.component';
import { AlchemedPreLoadData, CompositePatient, FullPatientDetails, PreparedEmailDTO, UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';

import { ArtiFormInstance, BiographicalDetails, CompletePatientDetails, ContactDetails, Patient, PersonResponsibleForAccount, TheatreSlate } from '@app/_alchemint/alchemint_dm';
import { Observable, Subject, catchError, firstValueFrom, map, of, switchMap, throwError } from 'rxjs';
import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { Artifact } from '@app/_alchemint/alchemint_dm';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UserInputTextComponent } from '@app/user-input-text/user-input-text.component';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { PatientComponent } from '@app/patient/patient.component';
import { ConsuDateInfo } from '@app/my-diary/my-diary.component';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { RQ_PAT_DETAILS, RQ_PAT_SIGN_CONTRACTS } from '@app/user-select-entity/user-select-entity.component';


@Injectable({
  providedIn: 'root'
})
export class PatientService {



async getPAtientPhoneNumber(id: string): Promise<string> {
  try {
    //const result = await this.apiInterfaceService.getPatientWithFullDetailsWebGuiApiCall(id).toPromise();
    const contactDetails = await firstValueFrom(this.webApiInterfaceService.getEntityByKey<ContactDetails>(new ContactDetails(), "PatientId", id));
    
    if (contactDetails && contactDetails.telephoneCell) {
      return contactDetails.telephoneCell;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
  _currentActivePatient : Patient;
  public get CurrentActivePatient () : Patient
  {
    return this._currentActivePatient;
  }

  public set CurrentActivePatient (value : Patient)
  {
     this._currentActivePatient = value;
  }

  private newPatientConsultEvent$ = new Subject<{ patient: Patient, dateInfo: any }>();

  constructor(private matDialog: MatDialog, private artiFormService: ArtiFormsService, private apiInterfaceService: ApiInterfaceService, private alchemintSharedService: AlchemintSharedService, private webApiInterfaceService : WebApiInterfaceService) { }

  public findPatientById(patientId: string): Observable<Patient> {
    return this.apiInterfaceService.getEntity<Patient>(new Patient(), patientId);
  }

  public findPatientComtactDetailsByPatientId(patientId: string): Observable<ContactDetails> {
    return this.webApiInterfaceService.getEntityByKey<ContactDetails>(new ContactDetails(), "PatientId", patientId);
  }


  isValidEmail(email: string): boolean {
    const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(email);
  }

  isValidPhoneNumber(phoneNum: string) {
    // Regular expression to match both international and local phone numbers
    const regex = new RegExp(/^(\+?[0-9]{1,3})?([0-9]{10,14})$/);
    return regex.test(phoneNum);
  }
  
  

  isValidEmailList(emailList: string): boolean {
    const emails = emailList.split(';');
    return emails.every(this.isValidEmail);
  }
  isValidEmailForPatient(patientId: string) : Observable<boolean>
  {
    return new Observable<boolean> (
      observer => {
        var sub = this.findPatientComtactDetailsByPatientId(patientId).subscribe(
          res => {
            if (res)
            {
              if (res.emailAddress)
              {
                if (this.isValidEmail(res.emailAddress) === true)
                {
                  observer.next	(true);
                }
                else
                {
                  observer.next	(false);
                }
              }
              else
              {
                observer.next	(false);
              }
            }
            else
            {
              observer.next	(false);
            }
          },
          err => { observer.error(err); },
          () => {
            sub.unsubscribe();
          }

        );
      }
    );

  }



  public showPatientQRCode(patient: Patient): void {

    try {
      this.artiFormService.userSelectForm(
        'Please select any additional forms you would like the patient to complete.', false, true, false, false, true, true, true, false, true, true, true).subscribe(
        selectedForms => {

          var patientCompleteDetailsRequested: boolean = false;
          var patientSignContractsRequested: boolean = false;
          var ids : string = null;
          if (selectedForms?.length > 0)
          {
            selectedForms.find(x => x.id === RQ_PAT_DETAILS) ? patientCompleteDetailsRequested = true : patientCompleteDetailsRequested = false;
            selectedForms.find(x => x.id === RQ_PAT_SIGN_CONTRACTS) ? patientSignContractsRequested = true : patientSignContractsRequested = false;
          
            ids = selectedForms.filter(x => (x.id !== RQ_PAT_DETAILS) && (x.id !== RQ_PAT_SIGN_CONTRACTS)).map(x => x.id).join(';');
            if (ids?.length === 0)
            {
              ids = null;;
            }

          }

          this.apiInterfaceService.generatePatientrequestQRCodeWebGuiApiCall(patient.id, ids, patientCompleteDetailsRequested, patientSignContractsRequested).subscribe(
            result => {

              //var base64QRCode = this.imageManipulationService.imageArrayToBase64(result);

              // var imageString: string = 'data:image/png;base64,' + result.qrCodeImageData;
              const base64Svg = btoa(result.qrCodeImageDataSvg);
              var imageString: string = 'data:image/svg+xml;base64,' + base64Svg;


              var userSeletInfo: UserSeletInfo = new UserSeletInfo();

              userSeletInfo.prompt = "Please scan the QR Code";
              userSeletInfo.patient = patient;
              // userSeletInfo.qrCodeImageBase64 = imageString;
              userSeletInfo.qrCodeImageBaseSvg = imageString;
              userSeletInfo.qrCodeUrl = result.qrCodeUrl;
              let dialogConfig: MatDialogConfig = new MatDialogConfig();

              dialogConfig = {
                maxWidth: '80vw',
                maxHeight: '100vh',
                data: userSeletInfo
              };

              const dialogRef = this.matDialog.open(QrCodeDisplayComponent, dialogConfig);

            },
            error => {
              return throwError(error);;
            }
          );
        },
        error => {
          error => {
            return throwError(error);
          }
        }
      );
    } catch (error) {
      throwError(error);
    }

  }

  public generatePatientRequestPreparedEmail(patient: Patient, existingFormInstanceId: string): Observable<PreparedEmailDTO> {
    return new Observable<PreparedEmailDTO>(
      observer => {
        try {
          
          if (existingFormInstanceId)
          {
            this.apiInterfaceService.generatePatientrequestEmailBodyWebGuiApiCall(patient.id, null, false, false, existingFormInstanceId ).subscribe(
              result => {
                observer.next(result);
              },
              error => {
                return throwError(error);;
              }
            );
          }
          else
          {
            this.artiFormService.userSelectForm('Please select any additional forms you would like the patient to complete.', false, true, false, false, true, true, true, false, true, true, true).subscribe(
              selectedForms => {
  
                var patientCompleteDetailsRequested: boolean = false;
                var patientSignContractsRequested: boolean = false;
                var ids : string = null;
                if (selectedForms?.length > 0)
                {
                  selectedForms.find(x => x.id === RQ_PAT_DETAILS) ? patientCompleteDetailsRequested = true : patientCompleteDetailsRequested = false;
                  selectedForms.find(x => x.id === RQ_PAT_SIGN_CONTRACTS) ? patientSignContractsRequested = true : patientSignContractsRequested = false;
                  ids = selectedForms.filter(x => (x.id !== RQ_PAT_DETAILS) && (x.id !== RQ_PAT_SIGN_CONTRACTS)).map(x => x.id).join(';');
                  if (ids?.length === 0)
                  {
                    ids = null;;
                  }
                }
  
                this.apiInterfaceService.generatePatientrequestEmailBodyWebGuiApiCall(patient.id, ids, patientCompleteDetailsRequested, patientSignContractsRequested, null ).subscribe(
                  result => {
                    observer.next(result);
                  },
                  error => {
                    return throwError(error);;
                  }
                );
              },
              error => {
                error => {
                  return throwError(error);
                }
              }
            );
  
  
  
          }          

        } catch (error) {
          throwError(error);
        }

      }
    );
  }




  public emailPatient(fullPatientDetails: FullPatientDetails, artifactId: string, attachmentFile: File, subject: string, body: string, hideCCInput: boolean, hideAttachButton: boolean): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        //this.showPialogProgress();
        var sub = this._emailPatient(fullPatientDetails, artifactId, attachmentFile, subject, body, hideCCInput, hideAttachButton).subscribe(
          emailed => {
            //this.close();
            observer.next((emailed === true));
          },
          error => {
            //this.close();
            observer.error(error);
          },
          ()=> {sub.unsubscribe();}
        );
      }
    );
  }

  public emailFileToAnyone(aboutPatientId: string, attachmentFile: File, subject: string, body: string, emailAddress : string = null, hideCCInput: boolean, hideAttachButton: boolean): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        //this.showPialogProgress();
        this._emailFileToAnyone(aboutPatientId, attachmentFile, subject, body, null, emailAddress, hideCCInput, hideAttachButton).subscribe(
          emailed => {
            //this.close();
            observer.next((emailed === true));
          },
          error => {
            //this.close();
            observer.error(error);
          }
        );
      }
    );
  }




  public emailPatientBlob(fullPatientDetails: FullPatientDetails, attachmentBlob: Blob, fileName: string, subject: string, body: string, hideCCInput: boolean, hideAttachButton: boolean): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        //this.showPialogProgress();
        this._emailPatient(fullPatientDetails, null, this.blobToFile(attachmentBlob, fileName), subject, body, hideCCInput, hideAttachButton).subscribe(
          emailed => {
            //this.close();
            observer.next((emailed === true));
          },
          error => {
            //this.close();
            console.log("Errors", error)
            observer.error(error);
          }
        );
      }
    );
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>b;

  }


  private showPialogProgress(): void {
    this.open();
  }
  open() {
    const dialogRef: MatDialogRef<ProgressbarComponent> = this.matDialog.open(ProgressbarComponent, {
      panelClass: 'transparent',
      disableClose: true,
      data: { percent: 30 }
    });

    setTimeout(() => {
      console.log('percent change to 50')
      //this.percent.percent = 50;

    }, 3000);

  }

  close() {
    this.matDialog.closeAll();
  }

  public _emailPatient(fullPatientDetails: FullPatientDetails, artifactId: string, attachmentFile: File, subject: string, emailbody: string, hideCCInput: boolean, hideAttachButton: boolean): Observable<boolean> {
    return new Observable<boolean>
      (
        observer => {

          if (!fullPatientDetails.contactDetails?.emailAddress) {
            this.alchemintSharedService.openErrorSnackBar("The patient does not have an email address");
            observer.error('The patient does not have an email address');
            return;
          }
           var userSeletInfo: UserSeletInfo = new UserSeletInfo();

          userSeletInfo.prompt = "Email Patient";
          userSeletInfo.fullPatientDetails = fullPatientDetails;
          userSeletInfo.dynamicProperties = {
            emailSubject: subject,
            attachmentArtifactId: artifactId,
            attachmentFileData: attachmentFile,
            emailBody: emailbody
          };

          userSeletInfo.hideCCInput = hideCCInput;
          userSeletInfo.hideAttachButton = hideAttachButton;

          let dialogConfig: MatDialogConfig = new MatDialogConfig();
          let dialogConfigNew: MatDialogConfig = new MatDialogConfig();

          dialogConfig = {
            // maxWidth: '80vw',
            // maxHeight: '100vh',
            // minWidth: '80vw',
            // minHeight: '70vh',
            width: '100%;',
            height: '100%;',
            data: userSeletInfo,
            panelClass: 'alchemintdialogpanel'

          };

          dialogConfigNew = {
            width: '80vw',
            maxHeight: '90vh', // Try adjusting these values
            maxWidth: '95vw',
              data: userSeletInfo,
            panelClass: 'alchemintdialogpanel'

          };

          const dialogRef = this.matDialog.open(EmailEditorComponent, dialogConfigNew);


          // {data : userSeletInfo, panelClass: 'alchemintdialogpanel'}
          dialogRef.afterClosed().subscribe(result => {

            observer.next(result);


          },
            error => {
              return throwError(error);
            }
          );
        }

      );




    //this.router.navigate(['mail/' + patient.id]);
  }

  public _emailFileToAnyone(aboutPatientId: string, attachmentFile: File, subject: string, emailbody: string, attachArtifactId: string, emailAddress: string, hideCCInput: boolean, hideAttachButton: boolean): Observable<boolean> {


    return new Observable<boolean>
      (
        observer => {


          var userSeletInfo: UserSeletInfo = new UserSeletInfo();

          userSeletInfo.prompt = "Send Email";
          userSeletInfo.allowEmailAddressEntry = true;
          userSeletInfo.patientId = aboutPatientId;
          userSeletInfo.dynamicProperties = {
            emailSubject: subject,
            attachmentArtifactId: attachArtifactId,
            attachmentFileData: attachmentFile,
            emailBody: emailbody,
            emailAddress: emailAddress 
            
          };
          userSeletInfo.hideCCInput = hideCCInput;

          userSeletInfo.hideAttachButton = hideAttachButton;


          let dialogConfig: MatDialogConfig = new MatDialogConfig();

          dialogConfig = {
            width: '100%;',
            height: '100%;',
            data: userSeletInfo,
            panelClass: 'alchemintdialogpanel'

          };

          const dialogRef = this.matDialog.open(EmailEditorComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            observer.next(result);
          },
            error => {
              observer.error(error);
            }
          );
        }

      );




    //this.router.navigate(['mail/' + patient.id]);
  }


  public deleteUnNeededExternalRequests(externalRequestIds: string): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.apiInterfaceService.deleteExternalRequests(externalRequestIds).subscribe(
          result => {
            observer.next(result);
          },
          error => {
            observer.error(error);
          }
        );
      }
    );
  }

  public userEnterTextWithPrompt(prompt: string, defaultValue: string): Observable<string> {
    return new Observable<string>(
      observer => {
        var userSeletInfo: UserSeletInfo = new UserSeletInfo();

        userSeletInfo.prompt = prompt;
        userSeletInfo.default = defaultValue;



        //this.alchemintSharedService.openIn
        const dialogRef = this.matDialog.open(UserInputTextComponent, { data: userSeletInfo, panelClass: 'alchemintdialogpanel', disableClose: true });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            observer.next(result);
          }
          else {
            observer.next(null);
            observer.complete();
          }
        },
          error => {
            observer.error(error);
          }
        );

      }
    );
  }


  public async userEnterTextWithPromptAsync(prompt: string, defaultValue: string): Promise<string> {
    const result$ = this.userEnterTextWithPrompt(prompt, defaultValue);
    return firstValueFrom(result$);
  }




  public deleteArtiFactWithConfirm(delArtifact: Artifact): Observable<Artifact> {
    return new Observable<Artifact>(
      observer => {
        var confirmMessage: string = `Are you sure you want to delete the file '${delArtifact.name}'?`;

        this.alchemintSharedService.confirmYesNoDialog(confirmMessage, false)?.subscribe(
          res => {
            if (res == true) {
              this.apiInterfaceService.deleteArtifact(delArtifact.id).subscribe(
                x => {
                  observer.next(delArtifact);
                  delArtifact = null;
                },
                error => {
                  observer.error(error);
                }
              );
            }
          }
        );

      }
    );
  }

  public deleteArtiFact(delArtifact: Artifact): Observable<Artifact> {
    return new Observable<Artifact>(
      observer => {
        this.apiInterfaceService.deleteArtifact(delArtifact.id).subscribe(
          x => {
            observer.next(delArtifact);
            delArtifact = null;
          },
          error => {
            observer.error(error);
          }
        );
      }
    );
  }


  public renameArtifact(artifact: Artifact): Observable<Artifact> {
    return new Observable<Artifact>(
      observer => {
        this.userEnterTextWithPrompt("Please enter new file name", artifact.nameWithoutExtension).subscribe(
          result => {
            if (result)
            {
              var newName: string = result + artifact.fileExtension;
              this.apiInterfaceService.renameArtifact(artifact.id, newName).subscribe(
                arti => {
                  if (arti) {
                    observer.next(arti)
                  }
                  else {
                    observer.complete();
                  }
  
                },
                err => {
                  observer.error(err);
                }
              );
  
            }
            else
            {
              observer.complete();
            }
          }
        );
      }
    );

  }


  public renameArtiformInstance(artiformInst: ArtiFormInstance): Observable<ArtiFormInstance> {
    return new Observable<ArtiFormInstance>(
      observer => {
        this.userEnterTextWithPrompt("Please enter new form name", artiformInst.name).subscribe(
          result => {
            if (result)
            {
              var newName: string = result;
            
              this.apiInterfaceService.renameArtiFormInstance(artiformInst.id, newName).subscribe(
                arti => {
                  if (arti) {
                    observer.next(arti)
                  }
                  else {
                    observer.complete();
                  }
  
                },
                err => {
                  observer.error(err);
                }
              );
  
            }
            else
            {
              observer.complete();
            }
          }
        );
      }
    );

  }
  


  public addConsultForSelectedDate(patient: Patient, dateInfo: ConsuDateInfo): void {
    this.newPatientConsultEvent$.next({ patient: patient, dateInfo: dateInfo })
  }

  public onAddConsultForSelectedDate(): Observable<{ patient: Patient, dateInfo: ConsuDateInfo }> {
    return this.newPatientConsultEvent$.asObservable();
  }

  public addPatientWithDialog(allowPastingPatientImage: boolean, mobileDevice: boolean): Observable<CompositePatient> {
    return new Observable<CompositePatient>(
      observer => {
        var userSeletInfo: UserSeletInfo = new UserSeletInfo();

        userSeletInfo.prompt = 'Add Patient';
        userSeletInfo.default = "";
        userSeletInfo.allowPastingPatientImage = allowPastingPatientImage;
        userSeletInfo.mobileDevice = mobileDevice;


        const dialogRef = this.matDialog.open(PatientComponent, { data: userSeletInfo, panelClass: 'alchemintdialogpanel', disableClose: true });

        dialogRef.afterClosed().subscribe(result => {

          if (result) {
            observer.next(<CompositePatient>result);
          }
          else {
            observer.complete();
          }
        },
          error => {
            observer.error(error);
          }
        );

      }

    );
  }
  // public softdeletePatientWithDialog3(patient: Patient): Observable<boolean> {
  //   return this.webApiInterfaceService.canDeletePatient(patient.id).pipe(
  //     switchMap(canDel => {
  //       if (canDel.result === false) {
  //         var msg: string = canDel.reason;
  //         this.alchemintSharedService.messageDialog(msg).subscribe();


          
  //         return of(false);
  //       } 
  //       else {
  //         return this.webApiInterfaceService.deleteEntity<Patient>(new Patient(), patient.id).pipe(
  //           map(res => true),
  //           catchError(err => {
  //             console.error(err);
  //             return of(false);
  //           })
  //         );
  //       }
  //     })
  //   );
  // }
  // public softdeletePatientWithDialog(patient: Patient): Observable<boolean> {
  //   return this.webApiInterfaceService.canDeletePatient(patient.id).pipe(
  //     switchMap(canDel => {
  //       if (canDel.result === false) {
  //         const msg: string = canDel.reason;
  //         this.alchemintSharedService.messageDialog(msg);
  //         return of(false);
  //       } else {
  //         return this.webApiInterfaceService.deleteEntity<Patient>(new Patient(), patient.id).pipe(
  //           map(() => {
  //             console.log('here1');
  //             return true;
  //           }),
  //           catchError(error => {
  //             console.log('here2');
  //             return of(false);
  //           })
  //         );
  //       }
  //     })
  //   );
  // }




  public canSoftDeletePatient(patient: Patient): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.webApiInterfaceService.canDeletePatient(patient.id).subscribe(canDel => {
        if (canDel.result === false) {
          var msg: string = canDel.reason;
          this.alchemintSharedService.messageDialog(msg).subscribe();
          observer.next(false);
        } 
        else if (canDel.isLinkedToiHealthAppointment === true)
        {
          this.alchemintSharedService.confirmYesNoDialog(
            'The Patient is linked to 1 or more iHealth Appointments. In this case you should usually be using the "Link to Patient" option on the appointment. Deleting a Patient that is linked to an existing iHealth appointment will result in a new patient being created the next time the diary is refreshed. Are you sure you want to delete the patient? <br> <br> <h2 style="background-color: yellow; color: red; font-weight: bolder;">Not recommended</h2>', false).subscribe(
            res => {
              if (res === true)
              {
                observer.next(true);
              }
              else
              {
                observer.next(false);
              }
            }
          );
        }
        else {
          observer.next(true);
        }
        //observer.complete();
      });
    });
  }
  public softDeletePatient(patient: Patient): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.webApiInterfaceService.deleteEntity<Patient>(new Patient(), patient.id).subscribe(res => {
        observer.next(true);
      },
        err => {
          observer.next(false);
        },
      );
    });
  }




//   public softdeletePatientWithDialogOld(patient: Patient): Observable<boolean> {
//     return new Observable<boolean>(observer => {
//       this.webApiInterfaceService.canDeletePatient(patient.id).subscribe(canDel => {
//         if (canDel.result === false) {
          
//           var msg: string = canDel.reason;

//           this.alchemintSharedService.messageDialogSync(msg);
//           observer.next(false);
//         } 
//         else {


//           this.webApiInterfaceService.deleteEntity<Patient>(new Patient(), patient.id).subscribe(res =>
//             {
// alert('here1');
//               observer.next(true);
//             },
//             err => {
//               alert('here2');
//               observer.next(false);
//             },

//           );

//         }
//         observer.complete();
//       });
//     });
//   }



  public editPatientWithDialog(fullPatientDetails: FullPatientDetails = null, patientId = null, preLoadData: AlchemedPreLoadData, deriveDateOfBirthFromIdNumber: boolean): Observable<CompositePatient> {
    return new Observable<CompositePatient>(
      observer => {
        var userSeletInfo: UserSeletInfo = new UserSeletInfo();

        userSeletInfo.prompt = 'Edit Patient';
        userSeletInfo.default = "";
        userSeletInfo.preLoadData = preLoadData;

        userSeletInfo.deriveDateOfBirthFromIdNumber = deriveDateOfBirthFromIdNumber;

        if (fullPatientDetails) {
          userSeletInfo.fullPatientDetails = fullPatientDetails;
        }
        else if (patientId) {
          userSeletInfo.patientId = patientId;
        }
        const dialogRef = this.matDialog.open(PatientComponent, { data: userSeletInfo, panelClass: 'alchemintdialogpanel' , disableClose: true});

        dialogRef.afterClosed().subscribe(result => {

          if (result) {
            observer.next(<CompositePatient>result);
          }
          else {
            observer.complete();
          }
        },
          error => {
            observer.error(error);
          }
        );

      }

    );
  }


  public  _sendPatientRequestEmail(fullPatientDetails: FullPatientDetails, alchemintSharedService: AlchemintSharedService, existingFormInstanceId: string): void {
    this.generatePatientRequestPreparedEmail(fullPatientDetails.patient, existingFormInstanceId).subscribe(
      preparedEmail => {
        var emailSubject: string = `Patient Details Required for ${fullPatientDetails.patient.firstName} ${fullPatientDetails.patient.lastName}`;
        this.emailPatient(fullPatientDetails, null, null, preparedEmail.subject, preparedEmail.emailBody, true, false).subscribe(
          result => {
            if (result === false) {
              // Delete External requests
              // this.deleteUnNeededExternalRequests(preparedEmail.externalRequestIds).subscribe(
              //   result => {
              //     if (result === true) {
              //     }
              //     else {
              //       alchemintSharedService.openSnackBar("⚠️ Could not delete requests.");
              //     }
              //   },
              //   error => {
              //     alchemintSharedService.openErrorSnackBar(error);
              //   }
              // );
              alchemintSharedService.openSnackBar('Email not sent');
            }
            else {
              // Show a toast of Email sent;
              alchemintSharedService.openSnackBar(`Email sent 📨 - ${fullPatientDetails.patient.firstName}  ${fullPatientDetails.patient.lastName}`);
            }

          },
          error => { alchemintSharedService.openErrorSnackBar(error); }
        );
      },
      error => {
        alchemintSharedService.openErrorSnackBar(error);
      }
    );

  }

  public buildDummyPatient () : CompletePatientDetails
  {
    var cp = new CompletePatientDetails();

    var patTest = new Patient();
    patTest.id = "x";
    patTest.firstName = "Joe";
    patTest.lastName = "Bloggs";
    patTest.title = "Mr";

    cp.patient = patTest;

    var bio = new BiographicalDetails();
    bio.id ="x";
    bio.dateOfBirth = new Date('13 Jan 1980');
    bio.homeLanguage = "EN";
    bio.idNumber = "8001135152087";
    bio.maritalStatus = "Married";
    bio.occupation = "Accountant";
    bio.nextOfKinName = "Perter Bloggs";
    bio.nextOfKinCell = "055555555";
    bio.sex = "Male";
    bio.patientId = "x";
    cp.biographicalDetails = bio;

    var con = new ContactDetails();
    con.id ="x";
    con.address = "44 Ronan Road";
    con.emailAddress = "jo.bloggs@nowhere.no";
    con.patientId = "x";
    con.postalCode = "3001";
    con.telephoneCell = "0555555555";
    con.telephoneHome = "0555555555";
    con.telephoneWork = "0555555555";

    cp.contactDetails = con;

    var presp = new PersonResponsibleForAccount();
    presp.id = "x";
    presp.patientId = "x";
    presp.dependantNumber = "01";
    presp.emailAddress = "jo.bloggs@nowhere.no";
    presp.employer = "BP";
    presp.gapCover = false;
    presp.mainMemberId ="800905222222";
    presp.medicalAidName = "Profmed";
    presp.medicalAidNumber = "0125687";
    presp.name = "Joe Bloggs";
    presp.occupation = "Doctor";
    presp.referringDoctor = "Dr Smith";
    presp.postalCode = "3001"
    presp.telephoneCell = "0555555555";
    presp.telephoneHome = "0555555555";
    presp.telephoneWork = "0555555555";
    presp.medicalAidName = "Healthscovery"
    presp.medicalAidNumber = "555555"
    cp.personResponsibleForAccount = presp;

    return cp;
  }


}


@Component({
  selector: 'progress-bar',
  template: `
  <div style="width:600px">
  <!-- <mat-progress-bar mode="determinate" [value]="data.percent.percent"></mat-progress-bar> -->
  HELLO WORLD
  </div>
  `,
  styles: [`h1 { font-family: Lato; }`]
})
export class ProgressbarComponent {
  // @Input() percent: number;
  data
  constructor(
    private dialogRef: MatDialogRef<ProgressbarComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    console.log(data);
  }
}
