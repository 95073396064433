
<div class="row;" >
    <svg-icon src="assets/svgs/cancel_black_24dp.svg" style="float: right; width: 2em;" (click)="close()"></svg-icon>
</div>

<div *ngIf="userSeletInfo?.qrCodeImageBaseSvg">
    <img [src]="userSeletInfo?.qrCodeImageBaseSvg" style="width:10cm; height:10cm;">
</div>

<div *ngIf="userSeletInfo?.qrCodeUrl">
    <a [href]="userSeletInfo?.qrCodeUrl" target="_blank">Link</a>
</div>


