import { EmbeddedViewRef, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, firstValueFrom } from 'rxjs';
import { Button, Buttons } from './message-box/message-box.module';
import { MessageBox } from './message-box/message-box.provider';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';
import { UserSeletInfo } from './_alchemint/alchemint_composite_requests';
import { Login } from './_alchemint/alchemint_dm';
import { UiErrorHelper } from './_helpers/uierror.helper';

@Injectable({
  providedIn: 'root'
})
export class AlchemintSharedService {
  


  constructor(private dialog : MatDialog, private matSnackBar: MatSnackBar, private mb:MessageBox) { }

  public notLicensedForAITranscriptionFeaturesMessage() {
    this.openMessageBoxSync("Not Licensed for AI Transcription", "You are not currently licensed for this fantastic feature. Please contact us to get it switched on.", Buttons.Ok);
  }

  public notLicensedForAIFeaturesMessage() {
    this.openMessageBoxSync("Not Licensed for AI", "You are not currently licensed for this fantastic feature. Please contact us to get it switched on.", Buttons.Ok);
  }
  
   public getActiveClinicians(allLogins : Login[]): Login[] {
    if (allLogins)
    {

      var clinicians : Login[] =
      allLogins.filter(x =>
        ((x.deleted ?? false) == false) &&
        ((x.isAdminStaff ?? false) == false) &&
        ((x.isBereauUser ?? false) == false) &&
        ((x.isLocum ?? false) == false) &&
        ((x.userName ?? '') !== 'master') &&
        ((x.userName ?? '') !== 'AlchemedSupport')
        )
        ;

      return clinicians;
    }
    else
    {
      return null;
    }
  }



  public confirmYesNoDialog(confirmQuestion : string, yesIsGood: boolean = true) :  Observable<boolean>
  {
    return this._confirmYesNoDialog(confirmQuestion, false, yesIsGood);
  }

  public async confirmYesNoDialogAsync(confirmQuestion : string, yesIsGood: boolean = true) : Promise<boolean>
  {
    var obs = this._confirmYesNoDialog(confirmQuestion, false, yesIsGood);
    return await obs.toPromise()
  }



  public messageDialog(message : string) :  Observable<boolean>
  {
    return this._confirmYesNoDialog(message, true);
  }

  public messageDialogSync(message : string) : void
  {
    var sub = this._confirmYesNoDialog(message, true).subscribe();
    sub.unsubscribe();
  }

  private _confirmYesNoDialog(confirmQuestion : string, showAsOkMessageBox : boolean,  yesIsGood: boolean = true) :  Observable<boolean>
  {
    var ob$ : Observable<boolean> = new Observable<boolean>();

    return new Observable<boolean> (
      (observer) => {

        var userSeletInfo : UserSeletInfo = new UserSeletInfo ();

        userSeletInfo.prompt = confirmQuestion;
        userSeletInfo.yesIsGood = yesIsGood;
        userSeletInfo.showOkOnly = (showAsOkMessageBox === true);

        let dialogConfig  : MatDialogConfig = new MatDialogConfig();

        dialogConfig = {
          maxWidth: '80vw',
          maxHeight: '100vh',
          data : userSeletInfo, 
          disableClose: true
        };

        const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);

        var sub = dialogRef.afterClosed().subscribe((result) => {


          var confirmed : boolean = (<boolean> result);
          if (confirmed == true)
          {
            observer.next(true);
          }
          else
          {
            observer.next(false);
          }

        },
        error => {
          sub.unsubscribe();
          alert(error);
            observer.next(false);
        },
        () => {
          sub.unsubscribe();
          observer.complete();
        }
        );


      }

    );



  }

  public openSnackBar(message : string, duration : number = 3000, includeDismiss : boolean = false) : void {
    var snackBarRef = this.matSnackBar.open (message, (includeDismiss === true ? "Dismiss" : null),  {
      duration: duration
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  public hideSnackBar ()
  {
    if (this.matSnackBar)
    {
      this.matSnackBar.dismiss();
    }

  }


  public  openSnackBarFromTemplate (message : TemplateRef<any>, duration : number = 3000) : MatSnackBarRef<EmbeddedViewRef<any>> {
    var snackBarRef = this.matSnackBar.openFromTemplate (message);
    return snackBarRef;

    // snackBarRef.onAction().subscribe(() => {
    //   snackBarRef.dismiss();
    // });
  }


  public openErrorSnackBar(message : string) : void {

    message = "⚠️ " +  message;

    var snackBarRef = this.matSnackBar.open (message, "Dismiss");
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }


  public oppenErrSnackBarForObjectErr (err : any)
  {
    this.openErrorSnackBar(UiErrorHelper.buildErrror(err,null).message);
  }
  public openErrDialogForObjectErr (err : any)
  {
    this.openMessageBoxSync('Something went wrong',UiErrorHelper.buildErrror(err,null).message, Buttons.Ok);
  }

  public openMessageBoxSync(title: string, message: string, buttons: Buttons, moreInfoLink: string = null)  : void
  {
    this.openMessageBox(title, message, buttons, null, moreInfoLink).subscribe();
  }

  public openOkMessageBoxSync(title: string, message: string)  : void
  {
    this.openMessageBoxSync(title, message, Buttons.Ok);
  }



  openMessageBox(title: string, message: string, buttons: Buttons, videoLink?: string, moreInfoLink?: string) : Observable<boolean>
  {
    return new Observable<boolean> (
      observer=> {
        let dialog = this.mb.show(message,title,buttons,videoLink, moreInfoLink);

        dialog.dialogResult$.subscribe(result=>{
          if (result === Button.Ok)
          {
            observer.next(true);
          }
          else if (result === Button.Yes)
          {
            observer.next(true);
          }
          else if (result === Button.No)
          {
            observer.next(false);
          }
        });
      }
    );
  }


  public async openMessageBoxAsync (title: string, message: string, buttons: Buttons, videoLink?: string, moreInfoLink?: string): Promise<boolean> {
    const result$ = this.openMessageBox(title, message, buttons, videoLink, moreInfoLink);
    return firstValueFrom(result$);
  }

  public stripScripts(s) : string {
    var div =   document.createElement('div');
    div.innerHTML = s;
    var scripts = div.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    var returnVal =  div.innerHTML;
    div.remove();
    return returnVal;

  }

}


