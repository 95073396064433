
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { SupportTicketChatItem } from '@app/_alchemint/alchemint_dm';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { Environment } from 'ag-grid-community';
import { EnvService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private hubConnection: signalR.HubConnection;
  private notificationSubject = new Subject<any>();
  private notificationSupportChatSubject = new Subject<any>();

  private connectionStatusSubject = new BehaviorSubject<boolean>(false);

  
  private get userName() : string{
    return this.auth.GetStoredUserWithNoPassword()?.userName;
  }

  
  constructor(private auth: AuthenticationService, private env: EnvService) {

  }

  private buildUniqueConnectionIdentifier(userId: string, supportTickeId: string): string
  {
    return `${userId}.${supportTickeId}`;
  }

  private get  supportChatUri () : string
  {
    return this.env.deploymentSettings.helpdDeskSignalRUrl;
  }
  
  
  


  initialize(uniqueUserId: string, supportTicketId: string)  
  {
    var userSupportTicketId = this.buildUniqueConnectionIdentifier(uniqueUserId, supportTicketId);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.supportChatUri, {
        accessTokenFactory: () => {
          // return `${this.apiKey}.${this.auth.userIdFromToken}` ; // Replace with your token retrieval logic
          return `${this.auth.userIdFromToken}` ; // Replace with your token retrieval logic
        },
        headers: {
          // 'ApiKey': this.apiKey, // Replace with your logic to get the ApiKey
          'Alcuser': this.userName, // Replace with your logic to get the Alcuser
          // 'Alcpswd': this.userPswd,
          'AlcuserId': userSupportTicketId ,
          'AlcUserName': `${this.auth.GetStoredUserWithNoPassword().userName}`, 
          'Token': this.auth.storedBearerToken
        }
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on('ReceiveMessage', (user, message, messageId, userId) => {
      this.notificationSubject.next({ user, message, messageId, userId });
    });

    this.hubConnection.on('ReceiveSupportTicketChatItem', (user, message, messageId, userId) => {

      var obj : SupportTicketChatItem = JSON.parse(message);
      this.notificationSupportChatSubject.next({ user, obj, messageId, userId });
    });

    



    this.hubConnection
    .start()
    .then(() => this.connectionStatusSubject.next(true))
    .catch(err => {
      console.error('SignalR Connection Error: ', err);

      this.connectionStatusSubject.next(false);
    });

  this.hubConnection.onclose(() => this.connectionStatusSubject.next(false));

  }

  async closeConnection()  {
    await this.hubConnection.stop();
  }
  public getConnectionStatus(): Observable<boolean> {
    return this.connectionStatusSubject.asObservable();
  }
  public getNotifications(): Observable<any> {
    return this.notificationSubject.asObservable();
  }

  public getSupportChatNotifications(): Observable<any> {
    return this.notificationSupportChatSubject.asObservable();
  }

  

  public sendSupportChatItemMessage(touUserId: string, supportTicketChatItem: SupportTicketChatItem): void {
    

    var userSupportTicketId = this.buildUniqueConnectionIdentifier(touUserId, supportTicketChatItem.supportTicketId);

    this.hubConnection.invoke('SendSupportChatItemMessage',userSupportTicketId,JSON.stringify(supportTicketChatItem))
      .catch(err => console.error('Error sending Hello World:', err));
  }



  // public sendTestMessage(): void {
  //   this.hubConnection.invoke('SendMessage','Alchemed-Support-1','Test Message')
  //     .catch(err => console.error('Error sending Hello World:', err));
  // }

  // public sendHelloWorld(): void {
  //   this.hubConnection.invoke('SendHelloWorld')
  //     .catch(err => console.error('Error sending Hello World:', err));
  // }

  public  GetConnectedUsers(): void {
    this.hubConnection.invoke('GetConnectedUsers')
      .catch(err => console.error('Error sending Hello World:', err));
  }


}
