import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  standalone: true,
  selector: 'one-time-passowrd-form',
  templateUrl: 'one-time-passowrd-form.component.html',
  styleUrls: ['one-time-passowrd-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatRadioModule, MatProgressSpinnerModule]
})
export class OneTimePassowrdFormComponent {

  @Output() onVerify = new EventEmitter<any>();
  @Output() onVerifyRecovery = new EventEmitter<any>();
  @Output('requestRecoveryOpt') onRequestRecoveryOtp = new EventEmitter<any>();
  @Output('resendOtp') resendOtp = new EventEmitter<any>();
  @Input() showRecoveryOptions = false;
  @Input() showRecoveryOptionForm = false;
  @Input() resending = false;
  @Input() allowOtpResending = false;
  @Input() mode:  "VERIFY" | "CONFIGURE" = "VERIFY";
  @Input() error?: string;

  recoveryOption: 'EMAIL' | 'SMS' | 'SUPPORT' | null = null;

  handleDigitInput(event: KeyboardEvent, prevInput: HTMLElement, nextInput: HTMLElement) {
    if (event.key === 'Backspace') {
      if (prevInput) {
        prevInput?.focus();
      }
    } else {
      const regex = /^\d$/;
      if (regex.test(event.key) && nextInput) {
        nextInput?.focus();
      }
    }
  }

  public verify(form: NgForm) {
    const keys = Object.keys(form.controls);
    const optValue = keys.map(key => form.controls[key].value).join('');
    if (this.showRecoveryOptionForm) {
      this.onVerifyRecovery.next(optValue);
    } else {
      this.onVerify.next(optValue);
    }
  }

  public get canRequestRecoveryCode() {
    return this.recoveryOption && this.recoveryOption !== 'SUPPORT';
  }

  public get showSupportContactLink() {
    return this.recoveryOption && this.recoveryOption === 'SUPPORT';
  }

  public requestRecoveryOtp() {
    this.onRequestRecoveryOtp.next(this.recoveryOption)
  }

  public resendCode() {
    if (!this.resending) {
      this.resendOtp.next(1);
    }
  }
}
