import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,  throwError, of,  BehaviorSubject, ReplaySubject, Subject, firstValueFrom } from 'rxjs';
// import { environment } from 'src/environments/environment';
import { catchError,  share,  } from 'rxjs/operators';
import *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { AccCustomerInvoice, AccCustomerPaymentDTO, AlchemedPreLoadData, AlchemintDataTable, ArtiFormBundle, ArtiFormInstanceBundle, CompositePatient, EmailResultDTO, eTaskGetStates, ExternalRqDetails, FullPatientDetails, PatientHistory, PaymentAmountAgainstInvoiceAllocatorDTO, PreparedEmailDTO, QrCodeDTO, TextDTO, UserSeletInfo, UserTaskDetail } from '@app/_alchemint/alchemint_composite_requests';
import { EnvService } from '@app/_services/environment.service';

import { DatePipe } from '@angular/common';






export class AlcConstants {
  public static ALL_IDS_IDENTIFIER: string = 'ALL';
}


@Injectable({
  providedIn: 'root'
})



export class ApiInterfaceService {
  private closeEmailDialog = new Subject<number>();

  errorMsg: string;
  artifactTypes: Alchemint.ArtifactType[] = null;
  public ICD_CACHE_KEY: string = "icd10cache";

  private currDate: Date;
  private timeZoneHoursOffset: number;

  private cachedICD10CodesObservable: Observable<Alchemint.ICD10Code[]> = null;


  public MAX_ALLOWED_FILE_SIZE_MB: number = 3;
  public MAX_ALLOWED_FILE_SIZE: number = this.MAX_ALLOWED_FILE_SIZE_MB * 1024 * 1024;

  private _maxFileSize: number = this.MAX_ALLOWED_FILE_SIZE;

  private _maxFileSizeMinumumThreshold: number = 1 & 1024 * 1024;

  public get maxFileSize(): number {
    if (this._maxFileSize < this._maxFileSizeMinumumThreshold) {
      return this._maxFileSizeMinumumThreshold;
    }
    else {
      return this._maxFileSize;
    }

  }

  public set maxFileSize(value: number) {
    this._maxFileSize = value;;
  }

  constructor(private http: HttpClient, private envService: EnvService, private datepipe: DatePipe) {

    this.currDate = new Date();
    this.timeZoneHoursOffset = Math.round(((-1) * (this.currDate.getTimezoneOffset() / 60)));
  }


  environment = this.envService.deploymentSettings;

  public apiUri = this.environment.apiUrl;
  artifactEndpoint = this.environment.artifactServiceEndPoint;
  publicContentEndPoint = this.environment.publicContentEndPoint;
  apiStatsUrl = this.environment.apiStatsUrl;
  apiWebUiUrl = this.environment.apiWebUiUrl;
  apiExtendedUrl = this.environment.apiExtendedUrl;

  cachedArtiForms: Observable<Alchemint.ArtiForm[]> = null;
  cachedArtiFactTypes: Observable<Alchemint.ArtifactType[]> = null;
  _cachedPreLoadData: Observable<AlchemedPreLoadData> = null;
  cachedAndDownloadedPreloadData: AlchemedPreLoadData = null;



  public clientCodeVersion: string;

  public tiggerCloseEmailDialog(): void {
    this.closeEmailDialog.next(1);
  }

  public onCloseEmailDialog(): Observable<number> {
    return this.closeEmailDialog.asObservable();
  }

  public clearCache(): void {

    this.clearAlchemedPreLoadData();
    this.cachedArtiForms = null;
    this.cachedArtiFactTypes = null;
    this._cachedPreLoadData = null;
    this.cachedAndDownloadedPreloadData = null;
    this.orgNameObservable$.next(null);

  }

  public SetPreloadDataCach(alchemedPreLoadData: AlchemedPreLoadData): void {
    this.cachedAndDownloadedPreloadData = alchemedPreLoadData;

    if (this.cachedArtiFactTypes == null) {
      this.cachedArtiFactTypes = new Observable<Alchemint.ArtifactType[]>(
        observer => { observer.next(alchemedPreLoadData.artifactTypes); }
      )
    }
  }


  public get cachedPreLoadData(): Observable<AlchemedPreLoadData> {
    if (this.cachedAndDownloadedPreloadData != null) {
      var ret = of(this.cachedAndDownloadedPreloadData);
      return ret;
    }
    else if ((this._cachedPreLoadData) && (this.cachedAndDownloadedPreloadData == null)) {
      this._cachedPreLoadData.subscribe(
        preLoadData => {
          this.cachedAndDownloadedPreloadData = preLoadData;
        }
      );
      return this._cachedPreLoadData
    }
    else {
      return this._cachedPreLoadData;
    }
  }

  public exportInvoices(ids: string[]): Observable<any> {
    const queryParams = ids.reduce((prev, curr, index) => index + 1 == ids.length ? prev += `Id=${curr}` : prev += `Id=${curr}&`, "");
    var uri = `${this.apiExtendedUrl}exportinvoice/csv?${queryParams}`;
    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
    });
    return this.http.get(uri, {
      headers: headers, responseType: 'blob' as any
    });
  }

  public fetchInvoiceExportData(fromDate: string, toDate: string): Observable<Alchemint.AccCustomerInvoiceHead[]> {
    const queryParams = `FromDate=${fromDate}&ToDate=${toDate}`
    const uri = `${this.apiExtendedUrl}exportinvoice/json?${queryParams}`;
    const headers = new HttpHeaders({ Accept: 'application/json' });
    return this.http.get<Alchemint.AccCustomerInvoiceHead[]>(uri, { headers: headers });
  }

  public checkCanReachServer(): Observable<string> {
    var queryStirng = this.apiUri;
    return this.http.get<string>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  private useDirectApi: boolean = false;
  private useDirectApiForCreate: boolean = true;
  public getEntities<T>(emptyEntity: Alchemint.IAlchemintEntity): Observable<T[]> {
    var queryStirng: string;
    if (this.useDirectApi) {
      queryStirng = this.apiUri + emptyEntity.entityTypeName + '/async';
    }
    else {
      queryStirng = this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getall`;
    }
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  // public getEntities <T>(emptyEntity : Alchemint.IAlchemintEntity): Observable<T[]>
  // {
  //   var queryStirng =  this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/getall`;
  //   return this.http.get<T[]>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  public getEntitiesByProperyStartsWith<T>(emptyEntity: Alchemint.IAlchemintEntity, propertyName: string, wildcard: string, orderByProperty: string = null, orderByDirection: string = "asc"): Observable<T[]> {
    let queryStirng = this.apiUri + emptyEntity.entityTypeName + `/simple?$filter=startswith('${encodeURIComponent(wildcard)}',${propertyName})=true`;
    let orderByString = "";
    if (orderByProperty) {
      orderByString = encodeURIComponent("&orderBy=" + orderByProperty + " " + orderByDirection);
      queryStirng = queryStirng + orderByString;
    }
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getEntitiesByNamedField<T>(emptyEntity: Alchemint.IAlchemintEntity, propertyName: string, propertyValue: string, sortProperty: string = null): Observable<T[]> {
    let queryStirng = this.apiUri + emptyEntity.entityTypeName + `/simple?$filter=${propertyName}${encodeURIComponent(` eq `)}${encodeURIComponent(propertyValue)}`;
    if (sortProperty != null) { queryStirng += `&$orderBy=${sortProperty} asc`; }
    return this.http.get<T[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  // public getEntity <T>(emptyEntity : Alchemint.IAlchemintEntity, id : string): Observable<T>
  // {
  //   const queryStirng = this.apiUri + emptyEntity.entityTypeName + "/" + id;
  //   return this.http.get<T>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  public getEntity<T>(emptyEntity: Alchemint.IAlchemintEntity, id: string): Observable<T> {
    var queryStirng: string;
    if (this.useDirectApi) {
      queryStirng = this.apiUri + emptyEntity.entityTypeName + "/" + id;
    }
    else {
      queryStirng = this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/get/${id}`;

    }
    return this.http.get<T>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  // public getEntityByMatchingProperties <T>(emptyEntity : Alchemint.IAlchemintEntity, entity : T): Observable<T[]>
  // {
  //   const queryStirng = this.apiUri + emptyEntity.entityTypeName + "?" + this.queryParamatersStringFromEntity<T>(entity);
  //   return this.http.get<T[]>(queryStirng)
  //     .pipe(catchError((err) => { return this.HandleError(err);}));
  // }

  public getEntityByMatchingProperties<T>(emptyEntity: Alchemint.IAlchemintEntity, entity: T): Observable<T[]> {
    const queryStirng = this.apiUri + emptyEntity.entityTypeName + '/async' + "?" + this.queryParamatersStringFromEntity<T>(entity);
    return this.http.get<T[]>(queryStirng).pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getAlchemintSettings(): Observable<Alchemint.SpaSettings> {
    return this.http.get<Alchemint.SpaSettings>('assets/alchemint.txt')
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }
  public getClientVersionInfo(): Observable<Alchemint.SpaSettings> {
    return this.http.get<Alchemint.SpaSettings>('alchemint_ver.txt?' + 'dummy=' + Math.random())
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public getArtifactTypes(): Observable<Alchemint.ArtifactType[]> {
    if (this.cachedArtiFactTypes) {
      return this.cachedArtiFactTypes;
    }
    else {
      this.cachedArtiFactTypes = this.getEntities<Alchemint.ArtifactType>(new Alchemint.ArtifactType());
      return this.cachedArtiFactTypes;
    }
  }

  public InitializeNetcashPayNow(userId: string): Observable<any> {
    const queryStirng = this.apiUri + 'netcashpaynow/initialize/'+userId;
    return this.http.get(queryStirng).pipe(catchError((err) => { return this.HandleError(err); }));
  }

  async getUnzippedFileDataForArtifact(artifactId: string): Promise<ArrayBuffer>
  {
    var afFile: any;

    afFile = await firstValueFrom(this.getArtifactFile (artifactId, null, null, null, null));

    var unzipped = await this.unzipArrayBufffer(afFile);

    return unzipped;

  }

  async unzipArrayBufffer(afFile: ArrayBuffer): Promise<ArrayBuffer>
  {


    const JSZipModule: any = await import('jszip');
    const JSZip = JSZipModule.default; // Explicitly use the default export
    const zipper = new JSZip();

    var zszip = await zipper.loadAsync(afFile);
    var filedata: ArrayBuffer;
    // New code to extract the first file as ArrayBuffer
    var fileNames = Object.keys(zszip.files);
    if (fileNames.length > 0) {
        var firstFileName = fileNames[0];
        var firstFile = zszip.files[firstFileName];
        filedata = await firstFile.async('arraybuffer');
        return filedata;
        // Now arrayBuffer contains the content of the first file in ArrayBuffer format
        // You can proceed with further processing here
    }
    else
    {
      throw new Error("The zip file is empty");

    }
  }


  throttle = (fn: Function, wait: number = 300): any => {
    let inThrottle: boolean,
      lastFn: ReturnType<typeof setTimeout>,
      lastTime: number;
    return function (this: any) {
      const context = this,
        args = arguments;
      if (!inThrottle) {
        fn.apply(context, args);
        lastTime = Date.now();
        inThrottle = true;
      } else {
        clearTimeout(lastFn);
        lastFn = setTimeout(() => {
          if (Date.now() - lastTime >= wait) {
            fn.apply(context, args);
            lastTime = Date.now();
          }
        }, Math.max(wait - (Date.now() - lastTime), 0));
      }
    };
  };

  // @Debounce(10000)
  public getAdmissions(userIds: string, inProgress: boolean): Observable<Alchemint.Admission[]> {
    if (userIds === AlcConstants.ALL_IDS_IDENTIFIER) {
      return this._getAdmissionsAllUser(inProgress);
    }
    else {
      if (userIds.indexOf(',') > 0) {
        var ids = userIds.split(',');
        return this._getAdmissionsMultiUser(ids, inProgress);
      }
      else {
        return this._getAdmissionsSingleUser(userIds, inProgress);
      }
    }

  }

  observable2: Observable<Alchemint.Admission[]> = null;
  observable3: any; //Observable<Alchemint.Admission[]> = null;

  stillExecuting: boolean = false;

  _getAdmissionsSingleUserThrottled(userIds: string, inProgress: boolean): Observable<Alchemint.Admission[]> {

    return new Observable<Alchemint.Admission[]>
      (
        observer => {
          if (this.stillExecuting == true) {

          }
          else {
            this.stillExecuting = true;
            setTimeout(() => {
              this.stillExecuting = false;
              this._getAdmissionsSingleUser(userIds, inProgress).subscribe(
                adms => {
                  observer.next(adms)
                }
              );

            }, 15000);

          }
        }
      )

  }

  public _getAdmissionsSingleUser(userId: string, inProgress: boolean): Observable<Alchemint.Admission[]> {
    var adm: Alchemint.Admission = new Alchemint.Admission();
    adm.userId = userId;
    adm.inProgress = inProgress;

    //console.log(`_getAdmissionsSingleUser `)
    return this.getEntityByMatchingProperties<Alchemint.Admission>(new Alchemint.Admission(), adm);
  }

  public _getAdmissionsMultiUser(userIds: string[], inProgress: boolean): Observable<Alchemint.Admission[]> {
    return new Observable<Alchemint.Admission[]>(
      observer => {

        this._getAdmissionsAllUser(inProgress).subscribe(
          admissions => {
            if (admissions?.length > 0) {
              observer.next(null);
            }
            else {
              observer.next(admissions?.filter(x => userIds.includes(x.userId)));
            }

          }
        );
      }
    );
  }

  public _getAdmissionsAllUser(inProgress: boolean): Observable<Alchemint.Admission[]> {
    var adm: Alchemint.Admission = new Alchemint.Admission();
    adm.inProgress = inProgress;
    return this.getEntityByMatchingProperties<Alchemint.Admission>(new Alchemint.Admission(), adm);
  }
  public _getConsultsAllUser(sFrom: string, sTo: string): Observable<Alchemint.Consult[]> {
    //Note that dates must be in format 'dd MMM yyyy'
    let queryStirng = this.apiUri + `consults/getconsultsfordaterange/${AlcConstants.ALL_IDS_IDENTIFIER}/${sFrom}/${sTo}`;
    return this.http.get<Alchemint.Consult[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public getUserTaskDetails(userId: string, eTaskGetStates: eTaskGetStates): Observable<UserTaskDetail[]> {
    var hds = {
      Accept: 'application/json' //,
      //WebUIControllersApiKey: webUIControllerapiKey
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + `/usertasks/${eTaskGetStates}/${userId}`;;
    var ret = this.http.get<UserTaskDetail[]>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
    return ret;

  }

  public getTheatreSlates(userId: string, date: string): Observable<Alchemint.TheatreSlate[]> {
    var hds = {
      Accept: 'application/json' //,
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + `/theatreslates/${userId}/${date}`;;
    var ret = this.http.get<Alchemint.TheatreSlate[]>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
    return ret;

  }


  public executePythonScript(artiformId: string, instanceId: string, patientId: string): Observable<string> {
    var hds = {
      Accept: 'application/json' //,
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + `/executepythonforform/${artiformId}/${instanceId}/${patientId}}`;;
    var ret = this.http.get<string>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
    return ret;

  }



  public getArtiForms(): Observable<Alchemint.ArtiForm[]> {
    if (this.cachedArtiForms) {
      return this.cachedArtiForms;
    }
    else {
      this.cachedArtiForms = this.getEntities<Alchemint.ArtiForm>(new Alchemint.ArtiForm());
      return this.cachedArtiForms;
    }
  }

  public generateTextFroOpenAI(prompt: string, apiKey: string): Observable<string> {
    const url = 'https://api.openai.com/v1/models/text-davinci-00';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    });
    const body = {
      prompt,
      max_tokens: 100,
      n: 1,
      stop: '\n',
    };
    return this.http.post<string>(url, body, { headers })
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public createEntity<T>(emptyEntity: Alchemint.IAlchemintEntity, entity: T): Observable<T> {
    var queryStirng: string;
    if (this.useDirectApiForCreate && emptyEntity.entityTypeName !== 'CustomShortcutKeys') {
      queryStirng = this.apiUri + emptyEntity.entityTypeName;
    }
    else {
      queryStirng = this.apiExtendedUrl + 'bridge/' + `${emptyEntity.entityTypeName}/add`;
    }
    return this.http.post<T>(queryStirng, entity)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public updateEntity<T>(emptyEntity: Alchemint.IAlchemintEntity, entity: T): Observable<T> {
    const queryStirng = this.apiUri + emptyEntity.entityTypeName;
    return this.http.put<T>(queryStirng, entity)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public deleteEntity<T>(emptyEntity: Alchemint.IAlchemintEntity, id: string): Observable<Object> {
    const queryStirng = this.apiUri + emptyEntity.entityTypeName + "/" + id;
    try {
      return this.http.delete(queryStirng)
        .pipe(catchError((err) => { return this.HandleError(err); }));
    }
    catch (error) {
      alert(error);
      return new Observable<boolean>(observer => { observer.next(false) });
    }
  }

  public getArtifactFile(id: string, isExternalCall: boolean, webUIControllerapiKey: string, requestDetails: string, requestId: string): Observable<any> {

    if (!id) {
      throw new Error('Attemt to get artifact of null id');
    }

    var queryStirng: string;

    if (isExternalCall == true) {
      queryStirng = this.apiUri + this.artifactEndpoint + '/file/' + id;
      var hds = {
        Accept: 'application/json',
        WebUIControllersApiKey: webUIControllerapiKey
      };

      queryStirng = this.apiWebUiUrl + 'externalapirequest' + '/' + encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId) + '/getartifactfile/' + id;
      var ret = this.http.get(queryStirng, { headers: hds, responseType: 'arraybuffer' })
        .pipe(catchError(
          (err) => {
            return this.HandleError(err);
          }));
      return ret;
    }
    else {
      queryStirng = this.apiUri + this.artifactEndpoint + '/file/' + id;
      return this.http.get(queryStirng, { responseType: 'arraybuffer' })
        .pipe(catchError((err) => { return this.HandleError(err); }));
    }

  }



  public createArtifact(parentId: string, file: File, artifactTypeId: string, functionalCode: string, id: string, metaData?: string): Observable<Alchemint.Artifact> {
    const queryParams = this.getArtifactQueryParamaters(file.name, parentId, artifactTypeId, file.size, functionalCode, id, metaData);
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile?" + queryParams;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Alchemint.Artifact>(queryString, formData, { reportProgress: true }
    )
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public updateArtifactOld(artifact: Alchemint.Artifact, file: File): Observable<Alchemint.Artifact> {
    artifact.unCompressedFileSize = file.size;
    const queryParams = this.jsonSerializeArtifact(artifact);
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile?" + queryParams;
    const formData = new FormData();

    formData.append('file', file);
    return this.http.put<Alchemint.Artifact>(queryString, formData)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  private lastUpdateTimes: Map<string, number> = new Map(); // Map to track last update times
  private updateCooldown = 3000; // Cooldown period in milliseconds (3 seconds)


  cnt: number = 0;
  public updateArtifact(artifact: Alchemint.Artifact, file: File): Observable<Alchemint.Artifact> {
    
    const currentTime = Date.now();
    const lastUpdateTime = this.lastUpdateTimes.get(artifact.id);

    // Check if the last update time is defined and within the cooldown period
    if (lastUpdateTime && (currentTime - lastUpdateTime < this.updateCooldown)) {
      // console.log(`Save of file prevented since the file was saved less than 3 seconds ago.`);
      return throwError(new Error('Save of file prevented since the file was saved less than 3 seconds ago.'));
    }
    // Clean the artifact object
    const cleanedArtifact = this.cleanArtifact(artifact);
    
    var queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/putwithfilenew";
    const formData = new FormData();

    // Append the file to the form data
    formData.append('file', file);

    // Append the cleaned artifact object as a JSON string
    formData.append('artifactJson', JSON.stringify(cleanedArtifact));

    // Log the form data for debugging
    //console.log(`FormData: ${formData}`);
    
    //console.log(`FormData: ${formData}`);

    this.cnt++;
    console.log(this.cnt);

    if (this.lastUpdateTimes.size > 10) {
      console.log('Resetting lastUpdateTimes map due to size limit');
      this.lastUpdateTimes.clear();
    }

    // Update the last update time
    this.lastUpdateTimes.set(artifact.id, currentTime);

    return this.http.put<Alchemint.Artifact>(queryString, formData)
      .pipe(catchError((err) => this.HandleError(err)));
}






  // Function to clean the artifact object by removing specified properties
  private cleanArtifact(artifact: Alchemint.Artifact): any {
      const { locked, missing, modDate,eventDate, compressedFileSize, ...cleanedArtifact } = artifact;
      
      return cleanedArtifact;
  }



  public renameArtifact(artifactId: string, newname: string): Observable<Alchemint.Artifact> {

    var currDate: Date = new Date();

    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/renameatifact/' + artifactId + '/' + encodeURIComponent(newname);

    return this.http.put<Alchemint.Artifact>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));

  }

  public renameArtiFormInstance(artiformId: string, newname: string): Observable<Alchemint.ArtiFormInstance> {

    var currDate: Date = new Date();

    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/renameatiform/' + artiformId + '/' + encodeURIComponent(newname);

    return this.http.put<Alchemint.ArtiFormInstance>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));

  }
  public createTheatreSlate(userId: string, file: File, date: Date, siteId: string): Observable<Alchemint.TheatreSlate> {

    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json'
    };
    var dt: string = this.datepipe.transform(date, 'dd MMM yyyy');

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + `/createtheatreslate2/${userId}/${dt}/${siteId}/${encodeURIComponent(file.name)}/${timeZoneHoursOffset}`;
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Alchemint.TheatreSlate>(queryStirng, formData)
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));

  }

  public createPatientNote(patientNote: Alchemint.PatientNote, file: File): Observable<Alchemint.PatientNote> {

    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    var hds = {
      Accept: 'application/json'
    };


    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + `/createpatientnote/${timeZoneHoursOffset}`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('patientNote', JSON.stringify(patientNote));

    return this.http.post<Alchemint.PatientNote>(queryStirng, formData)
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));

  }

  public deleteArtifact(id: string): Observable<Alchemint.Artifact> {
    const queryString = this.environment.apiUrl + this.environment.artifactServiceEndPoint + "/withfile/" + id;
    return this.http.delete<Alchemint.Artifact>(queryString)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getPublicContent(publicConytentKey: string, functionalCode: string): Observable<Alchemint.TextContent> {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/${publicConytentKey}/${functionalCode}`;
    return this.http.get<Alchemint.TextContent>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getWebAppItems(publicConytentKey: string, type: string): Observable<Alchemint.WebAppItem[]> {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/webappitems` + `/${publicConytentKey}/${type}`;
    return this.http.get<Alchemint.WebAppItem[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public getHelpTopics(publicConytentKey: string): Observable<Alchemint.Artifact[]> {
    const queryStirng = this.apiUri + this.publicContentEndPoint + `/helpfiletopics` + `/${publicConytentKey}`;
    return this.http.get<Alchemint.Artifact[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }




  public getPerformanceStats(uriAppend: string, goLangTimePeriod: string): Observable<Alchemint.PerfStats[]> {
    let queryStirng = this.apiStatsUrl + `PerformanceStats/history/${uriAppend}/${goLangTimePeriod}`;
    return this.http.get<Alchemint.PerfStats[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getUsageStats(uriAppend: string, year: number, month: number): Observable<Alchemint.OrgStats[]> {
    let queryStirng = this.apiStatsUrl + `PerformanceStats/orgstathist/${uriAppend}/${year}/${month}`;
    return this.http.get<Alchemint.OrgStats[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getExternalWebRequestApiCall(requestDetails: string, requestId: string, webUIControllerapiKey: string, resultsRequested: boolean): Observable<ExternalRqDetails> {
    var hds = {
      Accept: 'application/json',
      WebUIControllersApiKey: webUIControllerapiKey
    };

    var queryStirng = this.apiWebUiUrl + 'externalapirequest' + '/'
    if (resultsRequested == true) {
      queryStirng += 'results/';
    }
    queryStirng += encodeURIComponent(requestDetails) + '/' + encodeURIComponent(requestId);


    var ret = this.http.get<ExternalRqDetails>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
    return ret;

  }

  public getShortLink(id: string): Observable<string> {
    var hds = {
      Accept: 'application/json' //,
      //WebUIControllersApiKey: webUIControllerapiKey
    };

    var queryStirng = this.apiWebUiUrl + 'shortlinkwebui' + '/slredirect/' + id;

    var ret = this.http.get<string>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
    return ret;

  }



  public postArtiFormBundleWebGuiApiCall(postObject: any, patientId, artiformInstanceId: string, loginId: string, relatedConsultId: string, relatedConsultDate, doNotBillIfBillableForm: boolean, treatingPracNumber: string, practitionerId: string, useNewiHealthApi: boolean): Observable<ArtiFormInstanceBundle> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = ((-1) * (currDate.getTimezoneOffset() / 60));

    // Strange issue encountered when running from India wheee the time zone difference was 5.5
    // and the decimal was causing a bad request due to expected integer on Api
    timeZoneHoursOffset = Math.round(timeZoneHoursOffset);

    console.log(`postArtiFormBundleWebGuiApiCall timeZoneHoursOffset ${timeZoneHoursOffset}`);
    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };
    var queryStirng: string;

    if (relatedConsultId != null) {
      if (useNewiHealthApi)
      {
        queryStirng = this.apiExtendedUrl + 'webuserinterface/postartiformfornewihealthapiapi' + '/' + artiformInstanceId + '/' + patientId + '/' + relatedConsultId + '/' + relatedConsultDate + '/' + timeZoneHoursOffset + '/' + doNotBillIfBillableForm + '/' + loginId + '/' + practitionerId;
      }  
      else
      {
        queryStirng = this.apiExtendedUrl + 'webuserinterface/postartiform' + '/' + artiformInstanceId + '/' + patientId + '/' + relatedConsultId + '/' + relatedConsultDate + '/' + timeZoneHoursOffset + '/' + doNotBillIfBillableForm + '/' + loginId + '/' + treatingPracNumber;        
      }
      
    }
    else {
      queryStirng = this.apiExtendedUrl + 'webuserinterface/postartiform' + '/' + artiformInstanceId + '/' + patientId + '/' + timeZoneHoursOffset + '/' + loginId;
    }

    return this.http.post<ArtiFormInstanceBundle>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }
  public _apiKeyDetails: Alchemint.ApiKeys = null;
  private _apiKeyDetyailsAttempted: boolean = false;


  private _preloadDataInitted: boolean = false;

  public initPreLoadData() {
    if (this._preloadDataInitted === false) {
      this.getPreLoadDataWebGuiApiCall().subscribe(
        res => {
          this.alchPreLoadDataSubject.next(res);
          this._preloadDataInitted === true;
        },
        err => {
          console.log(err);
        }
      )
    }
  }

  public alchPreLoadDataSubject: BehaviorSubject<AlchemedPreLoadData> = new BehaviorSubject<AlchemedPreLoadData>(null);

  public get alchPreLoadData(): AlchemedPreLoadData {
    return this.alchPreLoadDataSubject.getValue();
  }


  public populateApiKeyDetails() {
    this._apiKeyDetyailsAttempted = true;
    var subs = this.getPreLoadDataWebGuiApiCall().subscribe(
      result => {
        this._apiKeyDetails = result.apiKeyDetails;
      },
      err => {

      },
      () => {
        subs.unsubscribe()
      }
    );
  }

  public get isBetaSite(): boolean {
    return false;
  }

  public getBillingInstructionsWebGuiApiCall(): Observable<AlchemintDataTable> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterfacebilling' + '/getbillinginstructions'

    var ret$ = this.http.get<AlchemintDataTable>(queryStirng, { headers: hds });

    return ret$;

  }


  public getInDevelopmentArtiFormBundleWebGuiApiCall(devArtifactId: string): Observable<ArtiFormBundle> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/smartform/dev/' + devArtifactId;

    var ret$ = this.http.get<ArtiFormBundle>(queryStirng, { headers: hds });

    return ret$;

  }


  public getOrganizationTemplatesWebGuiApiCall(): Observable<Alchemint.Artifact[]> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/orgtemplates';
    var ret$ = this.http.get<Alchemint.Artifact[]>(queryStirng, { headers: hds });

    return ret$;

  }

  public getOrganizationLogoWebGuiApiCall(): Observable<any> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/organization/logo';
    var ret$ = this.http.get(queryStirng, { headers: hds, responseType: 'arraybuffer' });
    return ret$;
  }

  public createartifactfromtemplateWebGuiApiCall(patientId: string, artifactTemplateId: string, userId: string, selectedFileName: string, targetArtifactId: string): Observable<Alchemint.Artifact> {
    var hds = {
      Accept: 'application/json'
    };

    if (!targetArtifactId) {
      targetArtifactId = 'NONE';
    }
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/createartifactfromtemplatewithfilename/' + `${patientId}/${artifactTemplateId}/${userId}/${selectedFileName}/${targetArtifactId}`;
    var ret$ = this.http.post<Alchemint.Artifact>(queryStirng, { headers: hds });
    return ret$;
  }

  public createletterforpatientWebGuiApiCall(patientId: string, artifactTemplateId: string, userId: string, fileName: string, letterContent: string): Observable<Alchemint.Artifact> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/createletterforpatient/' + `${patientId}/${artifactTemplateId}/${userId}/${fileName}`;

    var letterDetailsDTO: LetterDetailsDTO = new LetterDetailsDTO();
    letterDetailsDTO.content = letterContent;
    var ret$ = this.http.post<Alchemint.Artifact>(queryStirng, letterDetailsDTO, { headers: hds });
    return ret$;
  }

  public sendpatientrequestemailWebGuiApiCall(patientId: string, artiFormIds: string): Observable<boolean> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/sendpatientrequestemail/' + `${patientId}` + (artiFormIds === null ? '' : ('/' + artiFormIds));
    return this.http.post<boolean>(queryStirng, { headers: hds });
  }

  public generatePatientrequestQRCodeWebGuiApiCall(patientId: string, artiFormIds: string, requestPatientCompleteDetails: boolean, requestPatientSignContracts: boolean): Observable<QrCodeDTO> {

    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generatepatientrequestqrcode/' + `${patientId}` + (artiFormIds === null ? '/NONE' : ('/' + artiFormIds));


    queryStirng+=`/${requestPatientCompleteDetails}/${requestPatientSignContracts}`;
    //var ret$ = this.http.get<ArrayBuffer>(queryStirng, { headers : hds , responseType : 'arraybuffer'});
    var ret$ = this.http.post<QrCodeDTO>(queryStirng, { headers: hds });
    return ret$;
  }

  // public generateQRCodeForUriWebGuiApiCall(uri: string): Observable<ArrayBuffer> {

  //   var encodedUri = encodeURIComponent(uri);

  //   var hds = {
  //     Accept: 'application/json'
  //   };
  //   var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generateqrcodeforuri/' + encodedUri ;

  //   var ret$ = this.http.post<ArrayBuffer>(queryStirng, { headers: hds, responseType: 'arraybuffer' });
  //   return ret$;
  // }


  // public generatePatientrequestQRCodeWebGuiApiCall(patientId: string, artiFormIds: string, requestPatientCompleteDetails: boolean, requestPatientSignContracts: boolean): Observable<ArrayBuffer> {

  //   var hds = {
  //     Accept: 'application/json'
  //   };
  //   var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generatepatientrequestqrcode/' + `${patientId}` + (artiFormIds === null ? '/NONE' : ('/' + artiFormIds));


  //   queryStirng+=`/${requestPatientCompleteDetails}/${requestPatientSignContracts}`;
  //   //var ret$ = this.http.get<ArrayBuffer>(queryStirng, { headers : hds , responseType : 'arraybuffer'});
  //   var ret$ = this.http.post<ArrayBuffer>(queryStirng, { headers: hds, responseType: 'arraybuffer' });
  //   return ret$;
  // }


  public generateqrcodeforconf(): Observable<string> {

    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generateqrcodeforconf';

    var ret$ = this.http.post<string>(queryStirng, { headers: hds, responseType: 'arraybuffer' });
    return ret$;
  }





  public generatePatientrequestEmailBodyWebGuiApiCall(patientId: string, artiFormIds: string, requestPatientCompleteDetails: boolean, requestPatientSignContracts: boolean, existingFormInstanceId: string): Observable<PreparedEmailDTO> {

    var hds = {
      Accept: 'application/json'
    };
    var queryStirng : string;
    if (existingFormInstanceId)
    {
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generatepatientrequestforexistingformpreparedemail/' + `${patientId}/${existingFormInstanceId}`;
    }
    else
    {
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generatepatientrequestpreparedemail/' + `${patientId}` + (artiFormIds === null ? '/NONE' : ('/' + artiFormIds));
      queryStirng+=`/${requestPatientCompleteDetails}/${requestPatientSignContracts}`;
    }

    
    
    
    //var ret$ = this.http.get<ArrayBuffer>(queryStirng, { headers : hds , responseType : 'arraybuffer'});
    var ret$ = this.http.post<PreparedEmailDTO>(queryStirng, { headers: hds, responseType: 'arraybuffer' });
    return ret$;
  }

  public generatePatientrequestExistingFormEmailBodyWebGuiApiCall(patientId: string, artiFormInstanceId: string, requestPatientCompleteDetails: boolean, requestPatientSignContracts: boolean): Observable<PreparedEmailDTO> {

    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/generatepatientrequestforexistingformpreparedemail/' + `${patientId}` + `${artiFormInstanceId}`;
    queryStirng+=`/${requestPatientCompleteDetails}/${requestPatientSignContracts}`;
    //var ret$ = this.http.get<ArrayBuffer>(queryStirng, { headers : hds , responseType : 'arraybuffer'});
    var ret$ = this.http.post<PreparedEmailDTO>(queryStirng, { headers: hds, responseType: 'arraybuffer' });
    return ret$;
  }

  private _getObeserverError(error: string): Observable<any> {
    return new Observable<any>
      (
        observer => { observer.error(error) }
      );

  }


  public sendPatientEmail(patientId: string, subject: string, storeSentEmail: boolean, body: string, attachmentArtifactId: string, attachmentFiles: File[], ccEmails: string): Observable<EmailResultDTO> {

    if (!subject) {
      return this._getObeserverError('No email subject provided.');
    }

    if (!body) {
      return this._getObeserverError('No email body provided.');
    }


    var hds = {
      Accept: 'application/json'
    };

    var queryStirng;

    const formData = new FormData();
    if (attachmentFiles?.length > 0) {
      
      attachmentFiles.forEach((file) => {
        formData.append('files', file, file.name);
        formData.append('readablefilename', file.name);
      });
      
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/sendpatientemailwithattachmentnew/';
    }
    else {
      queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/sendpatientemailnew/';
    }

    queryStirng += `${patientId}/${subject}/${storeSentEmail}`;

    if (attachmentArtifactId) {
      queryStirng += `/${attachmentArtifactId}`;
    }

    formData.append('emailbody', body);
    formData.append('ccEmails', ccEmails);
    

    formData.append('test', 'test');
    var ret = this.http.post<EmailResultDTO>(queryStirng, formData, { headers: hds });

    return ret;
  }


  public getComputedArtifactFromWebGuiApiCall(patientId: string, computedNoteTypeId: string): Observable<string> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/getcomputedartifact/' + `${patientId}/${computedNoteTypeId}`;
    var ret$ = this.http.get<string>(queryStirng, { headers: hds });
    return ret$;
  }


  public getPatientWebGuiApiCall(patientId: string): Observable<ExternalRqDetails> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patient/' + patientId;
    var ret$ = this.http.get<ExternalRqDetails>(queryStirng, { headers: hds });
    return ret$;
  }

  public getPatientWithFullDetailsWebGuiApiCall(patientId: string): Observable<FullPatientDetails> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/fullpatientdetails/' + patientId;
    var ret$ = this.http.get<FullPatientDetails>(queryStirng, { headers: hds });
    return ret$;
  }


  public getNewPatientWebGuiApiCall(): Observable<ExternalRqDetails> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patient/new';
    var ret$ = this.http.get<ExternalRqDetails>(queryStirng, { headers: hds });
    return ret$;
  }

  public getPatientsByWildCardWebGuiApiCall(wildCard: string, searchField: string = null): Observable<Alchemint.Patient[]> {
    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patients/' + wildCard;
    if (searchField)
    {
      queryStirng += '/' + searchField;
    }
    var ret$ = this.http.get<Alchemint.Patient[]>(queryStirng, { headers: hds });
    return ret$;
  }

  private preloadWebCall$: Observable<AlchemedPreLoadData> = null;;

  orgNameObservable$: ReplaySubject<string> = new ReplaySubject<string>();


  //       if (this._preLoadDatObservable)
  //       {
  //         observer.next(this._preLoadDat);
  //       }
  //       else
  //       {
  //         this._getPreLoadDataWebGuiApiCall().subscribe(
  //           res => {
  //             this._preLoadDat = res;
  //             observer.next(this._preLoadDat);
  //           }
  //         );
  //       }
  //     }
  //   );
  // }

  // private preloadWebCall$ : Observable<AlchemedPreLoadData> = null;;


  // orgNameObservable$: ReplaySubject<string> = new ReplaySubject<string> ();




  public orgName$ (): Observable<string>
  {
    return this.orgNameObservable$.asObservable();
    // return this.getPreLoadDataWebGuiApiCall().pipe(map(x=> x.organisations[0]?.name));
  }

  public forcePreLoadDataReload(): void {
    this.cachedAndDownloadedPreloadData = null;
    this._cachedPreLoadData = null;
  }

  private alchemedPreLoadData$: ReplaySubject<AlchemedPreLoadData>;

  public getPreLoadDataWebGuiApiCallNew(): Observable<AlchemedPreLoadData> {

    if (!this.alchemedPreLoadData$) {
      this.alchemedPreLoadData$ = new ReplaySubject<AlchemedPreLoadData>(1);

      var hds = {
        Accept: 'application/json'
      };

      var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/preloaddata';

      this.http.get<AlchemedPreLoadData>(queryStirng, { headers: hds }).subscribe(
        (data) => {
          this.alchemedPreLoadData$.next(data);
          this.orgNameObservable$.next(data?.organisations[0]?.name)
        },
        (error) => {
          this.alchemedPreLoadData$.error(error);
        }
      );
    }

    return this.alchemedPreLoadData$.asObservable();
  }

  public clearAlchemedPreLoadData(): void {
    this.alchemedPreLoadData$ = null;
  }

  public orgApiKeyBehaveSubject$: BehaviorSubject<Alchemint.ApiKeys> = new BehaviorSubject<Alchemint.ApiKeys>(new Alchemint.ApiKeys());

  public getPreLoadDataAndSetGlobals(): Observable<AlchemedPreLoadData> {


    // this.getPreLoadDataWebGuiApiCall
    return new Observable<AlchemedPreLoadData>(
      observer => {
        this.getPreLoadDataWebGuiApiCall().subscribe(
          res=> {
            observer.next(res);

            this.envService.orgNameBehaveSubject$.next(res.organisations[0]?.name);
  
            this.orgApiKeyBehaveSubject$.next(res.apiKeyDetails);
            
            //this.orgNameObservable$.next(res.organisations[0]?.name);
          },
          err=> { observer.error(err); },
          ()=> { observer.complete(); }
        );
      }
    );

  }

  private preloadDataSubject = new BehaviorSubject<boolean>(false);
  
  private getPreLoadDataWebGuiApiCall(): Observable<AlchemedPreLoadData> {
    if (this.cachedAndDownloadedPreloadData) {
      return of(this.cachedAndDownloadedPreloadData);
    }
    else if (this.cachedPreLoadData) {
      return this.cachedPreLoadData;
    }
    else {
      var hds = {
        Accept: 'application/json'
      };

      var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/preloaddata';

      if (this.preloadWebCall$ == null) {
        var ret$ = this.http.get<AlchemedPreLoadData>(queryStirng, { headers: hds }).pipe(share());;
        this._cachedPreLoadData = ret$;

        ret$.subscribe({
          next: (data) => {
            this.preloadDataSubject.next(true); // Emit true on successful data load
          },
          error: (e) => 
          {
            console.log(e);
            this.preloadDataSubject.next(false) // Emit false on error
          }
        });


        this.preloadWebCall$ = ret$;
        return this._cachedPreLoadData;

      }
      else {
        return this.preloadWebCall$;
      }
    }

  }

  public getPreLoadDataUpdates(): Observable<boolean> {
    return this.preloadDataSubject.asObservable();
  }

  public updateOrgSettingsWebGuiApiCall(orgSettings: Alchemint.OrgSetting[]): Observable<boolean> {
    var currDate: Date = new Date();

    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/updateorgsettings';

    return this.http.put<boolean>(queryStirng, JSON.stringify(orgSettings), { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }

  public clientVersionInSyncWithServerWebGuiApiCall(version: string): Observable<boolean> {

    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/clientversioncheck/' + version;
    var ret$ = this.http.get<boolean>(queryStirng, { headers: hds });
    return ret$;
  }


  public clientVersionAtServerWebGuiApiCall(): Observable<string> {

    var hds = {
      Accept: 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/clientversionatserver';
    var ret$ = this.http.get<string>(queryStirng, { headers: hds });
    return ret$;
  }

  public putPatientWebGuiApiCall(patientId: string, postObject: any): Observable<CompositePatient> {
    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patient/' + patientId + '/' + this.timeZoneHoursOffset.toString();

    return this.http.put<CompositePatient>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }

  public postPatientWebGuiApiCall(patientId: string, postObject: any): Observable<CompositePatient> {
    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patient/' + patientId + '/' + this.timeZoneHoursOffset.toString();
    return this.http.post<CompositePatient>(queryStirng, postObject, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }

  public putRequestLetterToMemoWebGuiApiCall(patientId: string, letterTo: string): Observable<void> {
    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/requestletterinmemo/' + patientId + '/' + letterTo;
    return this.http.put<void>(queryStirng, null, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }

  public getAnaestheticRecordHtm(): Observable<string> {
    return this.http.get('assets/ANAESTHETIC RECORD.htm', { responseType: "text" })
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public triggerFileDownload(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.hidden == false;
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename; //filename + '.pdf';
    a.click();
    //return url;
  }

  private getArtifactQueryParamaters(name: string, parentId: string, artifactTypeId: string, uncompressedFileSize: number, functionalCode: string = null, id: string = null, metaData: string = null): string {
    const artifact: Alchemint.Artifact = new Alchemint.Artifact();
    artifact.parentId = parentId;
    artifact.patientId = parentId;
    artifact.name = name;
    artifact.artifactTypeId = artifactTypeId;
    artifact.unCompressedFileSize = uncompressedFileSize;
    artifact.functionalCode = functionalCode;
    artifact.metaData = metaData
    if (id != null) {
      artifact.id = id;
    }

    return this.jsonSerialize(artifact);
  }

  private HandleError(err: any): Observable<never> {
    if (err?.statusText) {
      console.log(err.statusText);
    }
    else {
      console.log("Missing error status");
      console.log(err);
    }

    if (err?.status == 400) {
      return this.HandleBadRequestError(err);
    }

    //alert(msg);
    return throwError(err);    //Rethrow it back to component
  }

  private HandleBadRequestError(err: any): Observable<never> {
    if (err?.error) {
      return throwError(err?.error);
    }
    else {
      return throwError(err);
    }
  }


  private queryParamatersStringFromEntity<T>(entity: T): string {
    return this.jsonSerialize(entity);
  }

  public jsonSerialize = function (obj: any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        if (obj[p] !== null) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }


      }
    return str.join("&");
  }

  public jsonSerializeArtifact = function (obj: any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        if ((p != "locked") && (p != "missing") && (p != "modDate") && (p != "eventDate") && (p != "compressedFileSize")) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }

      }
    return str.join("&");
  }

  getFileDownloadFormatIdentifier(fileExtension: string): any {
    var format: string;
    if (['.txt', '.rtf', '.htm', '.html', '.csv', '.log', '.json', '.md', '.msg', '.eml'].includes(fileExtension))
      format = 'text';
    else
      format = 'uint8array';
    return format;
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  private _usedGuids: Array<string> = [];
  public generateGuid(ensureNoUsed: boolean): string {
    var guid: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

    if (ensureNoUsed) {
      if (this._usedGuids.indexOf(guid) > -1) {
        guid = this.generateGuid(ensureNoUsed);
      }
      else {
        this._usedGuids.push(guid);
      }
    }
    return guid;
  }


  public stringifyObject(obj: any): string {
    if (obj) {
      return JSON.stringify(obj, null, 4);
    }
    else {
      return null;
    }
  }

  public postInvoice(accCustomerInvoice: AccCustomerInvoice): Observable<AccCustomerInvoice> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));
    // console.log(this.stringifyObject(accCustomerInvoice))
    const queryStirng = this.apiUri + `accounting/postinvoice/${timeZoneHoursOffset}`;
    return this.http.post<AccCustomerInvoice>(queryStirng, accCustomerInvoice)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public postPayment(accCustomerPaymentDTO: AccCustomerPaymentDTO): Observable<AccCustomerPaymentDTO> {
    var currDate: Date = new Date();
    var timeZoneHoursOffset: number = Math.round(((-1) * (currDate.getTimezoneOffset() / 60)));

    const queryStirng = this.apiUri + `accounting/postpayment/${timeZoneHoursOffset}`;
    return this.http.post<AccCustomerPaymentDTO>(queryStirng, accCustomerPaymentDTO)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public allocatePaymentAmounts(paymentAmountAgainstInvoiceAllocatorDTO: PaymentAmountAgainstInvoiceAllocatorDTO[]): Observable<PaymentAmountAgainstInvoiceAllocatorDTO[]> {
    const queryStirng = this.apiUri + `accounting/allocatepayments`;
    return this.http.post<PaymentAmountAgainstInvoiceAllocatorDTO[]>(queryStirng, paymentAmountAgainstInvoiceAllocatorDTO)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public getInvoices(customerAccoiuntId: string): Observable<AccCustomerInvoice[]> {
    const queryStirng = this.apiUri + `accounting/getinvoices/${customerAccoiuntId}`;
    return this.http.get<AccCustomerInvoice[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getPaymentsForDateRange(fromDate, toDate): Observable<AccCustomerPaymentDTO[]> {
    const queryStirng = this.apiUri + `accounting/getpaymentsforrange/${fromDate}/${toDate}`;
    return this.http.get<AccCustomerPaymentDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public searchPayments(filterOn,searchTerm: string): Observable<any[]> {
    const queryStirng = this.apiUri + `accounting/searchpayments?filterOn=${filterOn}&searchTerm=${searchTerm}`;
    return this.http.get<any[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }


  public searchInvoices(filterOn,searchTerm: string): Observable<any[]> {
    const queryStirng = this.apiUri + `accounting/searchinvoices?filterOn=${filterOn}&searchTerm=${searchTerm}`;
    return this.http.get<any[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getPayments(customerAccoiuntId: string): Observable<AccCustomerPaymentDTO[]> {
    const queryStirng = this.apiUri + `accounting/getpayments/${customerAccoiuntId}`;
    return this.http.get<AccCustomerPaymentDTO[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getInvoicesForDateRange(fromDate, toDate): Observable<AccCustomerInvoice[]> {
    const queryStirng = this.apiUri + `accounting/getinvoicesforrange/${fromDate}/${toDate}`;
    return this.http.get<AccCustomerInvoice[]>(queryStirng)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public postBillingRequestWebGuiApiCall(requestDetails: string): Observable<any> {
    var hds = {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    };

    var queryStirng = this.apiExtendedUrl + 'webuserinterfacebilling' + '/requestbillconsult/' + requestDetails;

    return this.http.post<any>(queryStirng, { headers: hds })
      .pipe(catchError(
        (err) => {
          return this.HandleError(err);
        }));
  }

  public getPatientHistoryWebGuiApiCall(patientId: string): Observable<PatientHistory> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/patienthistory/' + patientId;
    var ret$ = this.http.get<PatientHistory>(queryStirng, { headers: hds });
    return ret$;
  }

  public getCustomerAccountWebGuiApiCall(patientId: string): Observable<Alchemint.AccCustomerAccount> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/customeraccount/' + patientId;
    var ret$ = this.http.get<Alchemint.AccCustomerAccount>(queryStirng, { headers: hds });
    return ret$;
  }


  public attachInvoiceFile(invoiceHeadId: string, patientId: string, pdffile: File): Observable<Alchemint.Artifact> {
    var queryString = this.apiExtendedUrl + `webuserinterfacebilling/attachinvoicefile/${invoiceHeadId}/${patientId}`;
    const formData = new FormData();
    formData.append('file', pdffile);
    return this.http.post<any>(queryString, formData)
      .pipe(catchError((err) => { return this.HandleError(err); }));
  }

  public getAutoComplete(formFieldId: string): Observable<string[]> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/autocomplete/getforfield/${formFieldId}`, null);
    return this.http.get<string[]>(queryStirng, { headers: this.buildHeadsAppJson() });
  }

  public postAutoComplete(formFieldId: string, value: string): Observable<void> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/autocomplete/postforfield/${formFieldId}/${value}`, null);
    return this.http.post<void>(queryStirng, { headers: this.buildHeadsAppJson() });
  }




  public deleteAdmission(id: string): Observable<void> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deleteadmission/${id}`, null);
    return this.http.delete<void>(queryStirng, { headers: this.buildHeadsAppJson() });
  }

  public deleteTask(id: string): Observable<void> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deletetask/${id}`, null);
    return this.http.delete<void>(queryStirng, { headers: this.buildHeadsAppJson() });
  }

  public deleteExternalRequests(externalRequestIds: string): Observable<boolean> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deleteexrqs/${externalRequestIds}`, null);
    return this.http.delete<boolean>(queryStirng, { headers: this.buildHeadsAppJson() });
  }


  public deleteTheatre(theatreSlate: Alchemint.TheatreSlate): Observable<boolean> {
    var queryStirng = this.buildUri(eServiceApis.eExtendedApi, 'webuserinterface' + `/deletetheatreslate`, null);
    return this.http.delete<boolean>(queryStirng, { body: theatreSlate, headers: this.buildHeadsAppJson() });


  }



  public getActivatedicd10CodesWebGuiApiCall(): Observable<Alchemint.ICD10Code[]> {

    if (this.cachedICD10CodesObservable == null) {
      var hds = {
        Accept: 'application/json'
      };

      var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/geticd10codes';
      var ret$ = this.http.get<Alchemint.ICD10Code[]>(queryStirng, { headers: hds });
      this.cachedICD10CodesObservable = ret$;

    }
    return this.cachedICD10CodesObservable;
  }

  public geticd10CodesWebGuiApiCall(): Observable<Alchemint.ICD10Code[]> {

    if (this.cachedICD10CodesObservable == null) {

      var data = localStorage.getItem(this.ICD_CACHE_KEY);
      if (data) {
        var icd10CachedData: Alchemint.ICD10Code[] = JSON.parse(data);
        this.cachedICD10CodesObservable = new Observable<Alchemint.ICD10Code[]>(
          observer => { observer.next(icd10CachedData); }
        );
      }
      else {
        var hds = {
          Accept: 'application/json'
        };

        var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/geticd10codes';
        var ret$ = this.http.get<Alchemint.ICD10Code[]>(queryStirng, { headers: hds });
        this.cachedICD10CodesObservable = ret$;
        this.cachedICD10CodesObservable.subscribe(
          icd10s => {
            var data: string = JSON.stringify(icd10s);
            this.storeLargeDataLocalStorage(data, this.ICD_CACHE_KEY);

            if (data != this.getStoredLargeDataLocalStorage(this.ICD_CACHE_KEY)) {
              alert('NOT OK');
            }
            else {
              alert('OK');
            }
          }
        );

      }

    }
    return this.cachedICD10CodesObservable;
  }


  public getTestErrorWebGuiApiCall(testMessage: string): Observable<void> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/testerror/' + testMessage;
    var ret$ = this.http.get<void>(queryStirng, { headers: hds });
    return ret$;
  }

  public getDelayWebGuiApiCall(testDelay: number): Observable<void> {
    var hds = {
      Accept: 'application/json'
    };
    var queryStirng = this.apiExtendedUrl + 'webuserinterface' + '/testdelay/' + `${testDelay}`;
    var ret$ = this.http.get<void>(queryStirng, { headers: hds });
    return ret$;
  }

  private storeLargeDataLocalStorage(value: string, storageKeyPrefix: string): boolean {
    var chunkSize: number = 1000000;
    var i: number = 0;

    var chunkedData: string[] = [];

    var firstPart = value.substr(0, chunkSize);
    var reasembled: string = '';

    chunkedData.push(firstPart);

    var remaiing = value.substr(chunkSize);

    while (remaiing?.length > 0) {
      firstPart = remaiing.substr(0, chunkSize);
      chunkedData.push(firstPart);
      remaiing = remaiing.substr(chunkSize);


    }

    chunkedData.forEach(
      x => {
        reasembled += x;
      }
    );

    if (reasembled != value) {

      return false;
    }
    else {
      chunkedData.forEach(
        x => {
          i++;
          try {
            localStorage.setItem(storageKeyPrefix + '_' + i.toString(), x);
          }
          catch (error) {
            alert(x.length + '                       ' + error);
          }

        }
      );

      return true;
    }
  }

  private getStoredLargeDataLocalStorage(storageKeyPrefix: string): string {
    var ret: string = '';
    var i: number = 1;

    var data = localStorage.getItem(storageKeyPrefix + '_' + i.toString());
    while (data.length > 0) {
      ret += data;
      i++;
      data = localStorage.getItem(storageKeyPrefix + '_' + i.toString());
    }
    return ret;

  }

  private buildHeadsAppJson(): any {
    var hds = { Accept: 'application/json' };
    return hds;
  }

  private buildUri(apiOption: eServiceApis, path: string, qry: string): string {
    var queryStirng: string;
    if (apiOption == eServiceApis.eCoreApi) {
      queryStirng = this.apiUri;
    }
    else if (apiOption == eServiceApis.eExtendedApi) {
      queryStirng = this.apiExtendedUrl;
    }
    else if (apiOption == eServiceApis.eBIApi) {
      queryStirng = this.apiExtendedUrl;
    }

    queryStirng = queryStirng + path;

    if (qry) {
      queryStirng = queryStirng + "?" + qry;
    }

    return queryStirng;
  }




}

export enum eServiceApis {
  eCoreApi,
  eExtendedApi,
  eBIApi
}


export class FormFileDataDto {
  constructor(public TheFile: File, public FileName: string) {

  }
}

export class LetterDetailsDTO {
  public patientId: string;
  public content: string;
}
